export const educationData = [
    {
        id: 1,
        institution: 'California Polytechnic State University - San Luis Obispo',
        course: 'Computer Science',
        startYear: '2014',
        endYear: '2020',
        college: 'College of Engineering',
        degreeType: 'Bachelor of Science'
    }
]
